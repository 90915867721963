import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BaseModal from "./BaseModal";

let openModalFunction = null;

const CustomActionModal = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const handleClose = () => {
    setIsOpen(false);
    if (modalProps.navigateTo) {
      navigate(modalProps.navigateTo);
    }
  };

  const handleOpen = (props) => {
    setModalProps(props);
    setIsOpen(true);
  };

  // Storing handleOpen for external use
  openModalFunction = handleOpen;

  // Split the content by new lines and map to paragraphs if it's a string
  const renderContent = () => {
    if (typeof modalProps.content === "string") {
      return modalProps.content
        .split("\n")
        .map((line, index) => <p key={index}>{line}</p>);
    }
    return modalProps.content; // Return as is if not a string
  };

  // Modify actions to include handleClose
  const modifiedActions = modalProps.actions
    ? modalProps.actions.map((action) => ({
        ...action,
        onClick: () => {
          action.onClick();
          if (action.closeOnClick !== false) {
            handleClose();
          }
        },
      }))
    : [{ label: "Close", onClick: handleClose }];

  return (
    <BaseModal
      isOpen={isOpen}
      title={modalProps.title || ""}
      onClose={handleClose}
      actions={modifiedActions}
    >
      {renderContent()}
    </BaseModal>
  );
};

export const openCustomActionModal = (props) => {
  if (openModalFunction) {
    openModalFunction(props);
  }
};

export default CustomActionModal;
