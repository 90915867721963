import { useRef, useEffect, useCallback } from "react";

const useGreeting = (firstCallViewTone, secondCallViewTone, callHasStarted) => {
  const firstAudio = useRef(null);
  const secondAudio = useRef(null);
  const isUnmounted = useRef(false);

  useEffect(() => {
    isUnmounted.current = false;

    if (firstCallViewTone && firstCallViewTone.trim() !== "") {
      firstAudio.current = new Audio(firstCallViewTone);
      console.log("firstAudio.current", firstAudio.current);
      console.log("firstCallViewTone", firstCallViewTone);
      firstAudio.current.loop = true;
      firstAudio.current.load();
    }

    if (secondCallViewTone && secondCallViewTone.trim() !== "") {
      secondAudio.current = new Audio(secondCallViewTone);
      secondAudio.current.load();
    }

    return () => {
      isUnmounted.current = true;
      stopGreeting();
    };
  }, [firstCallViewTone, secondCallViewTone]);

  const stopGreeting = useCallback(() => {
    if (firstAudio.current) {
      firstAudio.current.pause();
      firstAudio.current.currentTime = 0;
    }
    if (secondAudio.current) {
      secondAudio.current.pause();
      secondAudio.current.currentTime = 0;
    }
  }, []);

  const playGreeting = useCallback(() => {
    if (!isUnmounted.current && firstAudio.current) {
      stopGreeting();
      firstAudio.current
        .play()
        .catch((error) =>
          console.error("Error playing the first tone:", error),
        );
    }
    console.log("firstAudio.current when playing", firstAudio.current);
    console.log("isUnmounted.current", isUnmounted.current);
  }, [stopGreeting, firstCallViewTone]);

  const handleCanPlayThrough = useCallback(() => {
    if (callHasStarted && !isUnmounted.current && secondAudio.current) {
      if (firstAudio.current) {
        firstAudio.current.pause();
        firstAudio.current.currentTime = 0;
      }
      secondAudio.current
        .play()
        .catch((error) =>
          console.error("Error playing the second tone:", error),
        );
    }
  }, [callHasStarted, secondCallViewTone]);

  useEffect(() => {
    if (secondAudio.current) {
      secondAudio.current.addEventListener(
        "canplaythrough",
        handleCanPlayThrough,
      );

      return () => {
        secondAudio.current.removeEventListener(
          "canplaythrough",
          handleCanPlayThrough,
        );
        stopGreeting();
      };
    }
  }, [handleCanPlayThrough, stopGreeting]);

  return { callHasStarted, playGreeting, stopGreeting };
};

export default useGreeting;
