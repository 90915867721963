import { openCustomActionModal } from "components/BaseModal/CustomActionModal";

export const handleMicrophonePermissions = async (navigate) => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    alert(
      "Your browser doesn't support accessing the microphone. Please try using a modern browser like Chrome, Firefox, or Edge.",
    );
    return;
  }

  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.start();
    mediaRecorder.stop();
    stream.getTracks().forEach((track) => track.stop());
    navigateAndRefresh(navigate);
  } catch (err) {
    console.error("Failed to get microphone permission:", err);
    showMicrophonePermissionsModal(navigate);
  }
};

const showMicrophonePermissionsModal = (navigate, isRetry = false) => {
  const browserName = getBrowserName();
  const instructions = getMicrophoneInstructions(browserName);

  const requestAccess = async () => {
    const accessGranted = await checkMicrophoneAccess();
    if (accessGranted) {
      navigateAndRefresh(navigate);
    } else {
      // If access is not granted, show the modal again with the retry message
      showMicrophonePermissionsModal(navigate, true);
    }
  };

  const initialContent = `We couldn't access your microphone. Please follow these steps to enable it:

${instructions}

After following these steps, click the button below to try again.`;

  const retryContent = `It seems we still can't access your microphone. Please make sure you've completed these steps:

${instructions}

If you're still having trouble, try refreshing the page or checking your browser settings.`;

  openCustomActionModal({
    title: isRetry
      ? "Microphone Access Still Required"
      : "Microphone Access Required",
    content: isRetry ? retryContent : initialContent,
    actions: [
      {
        label: isRetry
          ? "I've double-checked, try again"
          : "I've enabled the microphone",
        onClick: requestAccess,
        highlight: true,
      },
    ],
    closeOnClickOutside: false,
  });
};

const checkMicrophoneAccess = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    stream.getTracks().forEach((track) => track.stop());
    return true;
  } catch (err) {
    console.error("Failed to get microphone permission:", err);
    return false;
  }
};

const getBrowserName = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (userAgent.indexOf("chrome") > -1) return "Chrome";
  if (userAgent.indexOf("firefox") > -1) return "Firefox";
  if (userAgent.indexOf("safari") > -1) return "Safari";
  if (userAgent.indexOf("edge") > -1) return "Edge";
  return "Unknown";
};

const getMicrophoneInstructions = (browser) => {
  switch (browser) {
    case "Chrome":
      return "1. Click the settings icon in the address bar\n2. Click 'Site settings'\n3. Allow microphone access";
    case "Firefox":
      return "1. Click the lock icon in the address bar\n2. Clear the 'Block' setting for the microphone\n3. Refresh the page";
    case "Safari":
      return "1. Open Safari Preferences\n2. Go to Websites > Microphone\n3. Allow access for this website";
    case "Edge":
      return "1. Click the lock icon in the address bar\n2. Allow microphone access\n3. Refresh the page";
    default:
      return "Please check your browser settings to allow microphone access, then refresh the page.";
  }
};

const navigateAndRefresh = (navigate) => {
  navigate("/my-simulations");
  window.location.reload();
};
