import { setMiniSidenav } from "context/materialUIController";
import { handleMicrophonePermissions } from "./microphonePermissions";

export const setupVapiEventListeners = (
  vapiRef,
  stopGreeting,
  setIsExerciseEndModalOpen,
  setCallHasStarted,
  dispatchMaterialUIController,
  volumeLevel,
  navigate,
) => {
  if (!vapiRef.current) return;
  console.log("vapiRef.current updated", vapiRef.current);

  let isErrorStop = false;

  vapiRef.current.on("message", (message) => {
    console.debug(message);
  });

  vapiRef.current.on("error", (e) => {
    console.error(e);

    if (
      e.error &&
      e.error.type === "permissions" &&
      e.action === "camera-error"
    ) {
      handleMicrophonePermissions(navigate);
      stopGreeting();
      isErrorStop = true;
      vapiRef.current.stop();
    }
  });

  vapiRef.current.on("volume-level", (volume) => {
    volumeLevel.current = volume;
  });

  vapiRef.current.on("call-end", () => {
    if (!isErrorStop) {
      setIsExerciseEndModalOpen(true);
    }
    setCallHasStarted(false);
    console.log("Call has ended.");
    isErrorStop = false; // Reset the flag
  });

  vapiRef.current.on("call-start", () => {
    setCallHasStarted(true);
    setTimeout(() => {
      setMiniSidenav(dispatchMaterialUIController, true);
    }, 1000);
    console.log("Call has started.");
  });

  vapiRef.current.on("speech-end", () => {
    console.log("Assistant speech has ended.");
  });

  vapiRef.current.on("speech-start", () => {
    console.log("Assistant speech has started.");
  });
};
